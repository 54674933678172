<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
    size="lg"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard>
      <CCardBody>
        <CInput
          label="Name"
          placeholder="Region name"
          v-model="region_updated.name"
        />
        <CInput
          label="Lat"
          placeholder="Latitue"
          v-model="region_updated.lat"
        />
        <CInput
          label="Lng"
          placeholder="Longitude"
          v-model="region_updated.lng"
        />
      </CCardBody>
      <CCardFooter>
        <GoogleMap
          v-if="show"
          :height="'400px'"
          :search="false"
          @updatedLatLng="updatedLatLng"
          :zoom="9"
          :lat="Number(region_updated.lat)"
          :lng="Number(region_updated.lng)">
        </GoogleMap>
      </CCardFooter>
    </CCard>
    <template #header>
      <h6 class="modal-title">{{title}}  {{" - STEP ("+(step+1)+"/1)"}}</h6>
      <CButtonClose @click="onCancel" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">Cancel</CButton>
      <CButton @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>

</template>
<script>
import axios from 'axios';
import GoogleMap from '@/components/GoogleMapLoader.vue';

export default {
  components : {
    GoogleMap,
  },

  props : {
    title : {type:String, default:"New Region"},
  },

  data(){
    return {
      show : false,
      step : 0,

      region_updated:{
        id:null,
        name:null,
        lat:null,
        lng:null,
        organization:null,
      },
      form_type : "",

      notification: '',
      dismissCountDown: 0,
    }
  },

  methods : {
    open(type, value){
      this.form_type = type;
      if(type==="add"){
        this.initializeAdd(value);
      }
      if(type==="edit"){
        this.initializeEdit(value);
      }
      
      this.show = true;
      this.step = 0;
    },

    initializeAdd(current_position){
      this.current_position = {};
      if(current_position && 
          Object.keys(current_position).includes('lat') &&
          Object.keys(current_position).includes('lng'))
      { 
        this.current_position['lat'] = current_position['lat'];
        this.current_position['lng'] = current_position['lng'];
      }else{ //current_position 을 받지 않았을 경우, 기본값(서울 시청) 설정
        this.current_position['lat'] = 37.566782;
        this.current_position['lng'] = 126.978131;
      }

      this.region_updated = {
        id:null, name:null, organization:null,
        lat:this.current_position['lat'],
        lng:this.current_position['lng'],
      };
    },
    initializeEdit(region_id){
      this.getRegion(region_id);
    },

    getRegion(id){
      axios.get(`/api/regions/${id}/`)
      .then((result)=>{
        this.region_updated = JSON.parse(JSON.stringify(result.data));
      })
    },

    onCancel(){
      this.region_updated = {
          id:null,
          name:null,
          lat:null,
          lng:null,
          organization:null,
        };
      this.show = false;
    },
    onSubmit() {
      if(this.validate_basic()){
        if(this.form_type==="edit") 
          this.$emit('edit-payload', this.region_updated);
        else if(this.form_type==="add")
          this.$emit('add-payload', this.region_updated);
        this.show = false;
      }else{
        return;
      }
    },
    validate_basic(){
      if (!this.region_updated.name) {
        this.notification = 'Name is required';
        this.dismissCountDown = 3;
        return false;
      }
      if (!this.region_updated.lat || !this.region_updated.lng) {
        this.notification = 'Location error';
        this.dismissCountDown = 3;
        return false;
      }
      return true;
    },
    updatedLatLng(lat_lng){
      this.region_updated.lat = Number(lat_lng.lat);
      this.region_updated.lng = Number(lat_lng.lng);
    }
  },
}
</script>