<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    size="lg"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CTableWrapper
      v-if="list && list.length"
      :items="list"
      :fields="FIELDS"
      header-icon="cil-puzzle"
      :add-btn="false"
      :view-btn="false"
      :edit-btn="false"
      caption="Subscription"
      hover
      small
      fixed
      :importBtn="false"
      :checkable="false"
      @delete="onDelete"
    />
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <CSelect
              label="Service"
              horizontal
              :options="SERVICE_OPTS"
              :value.sync="service_key"
            />
            <CInput
              label="Duration"
              horizontal
              type="number"
              v-model="days"
            >
              <template #append-content>{{ $t('cloud.title.unit_day') }}</template>
            </CInput>
          </CCol>
          <CCol sm="2">
            <CButton @click="onAdd" color="info">Add</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <template #header>
      <h6 class="modal-title">Subscriptions</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onClose" color="primary">Close</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios'
import CTableWrapper from '@/components/EdxTable'
import { mapState } from 'vuex'

export default {
  name: 'SubscriptionForm',

  components: {
    CTableWrapper,
  },
  computed : {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      show: false,
      notification: '',
      dismissCountDown: 0,

      FIELDS: [
        {key: 'service_name', label: 'Service', _classes: 'align-middle'},
        {key: 'remain', label: 'Remain', _classes: 'align-middle'},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],

      service_key: null,
      days: null,
      SERVICE_OPTS: [],
      list: []
    }
  },
  methods: {
    open() {
      this.clear();
      this.initialize();
      this.show = true;
    },
    initialize() {
      this.list = this.capability.organization.subscription.map(el => {
        return {
          id: el.id,
          service_name: el.service_name,
          service_key: el.service_key,
          remain: this.$utility.getSubscriptionStatusRemain(el)
        }
      });
      this.SERVICE_OPTS = this.capability.services.map(el => {
        return {
          value: el.key,
          label: el.name
        }
      });
      this.SERVICE_OPTS.unshift({
        value: null,
        label: 'Select'
      });
    },
    clear() {
      this.show = false;
      this.service_key = null;
      this.days = null;
      this.SERVICE_OPTS = [];
      this.list = [];
    },
    validateAndWarning() {
      if (!this.service_key) {
        this.notification = this.$t('cloud.title.err_service_key');
        this.dismissCountDown = 3;
        return true;
      }
      if (!this.days) {
        this.notification = this.$t('cloud.title.err_period_day');
        this.dismissCountDown = 3;
        return true;
      }
      return null;
    },
    onClose() {
      this.clear();
    },
    onAdd() {
      if (this.validateAndWarning()) {
        return;
      }
      this.$confirm(this.$t('cloud.title.add_subscription'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        let dup = this.list.find(el => el.service_key === this.service_key);
        if (dup && dup.remain !== 'Expired') {
          axios.put(`/api/install/subscriptions/${dup.id}/`, {
            period_day: this.days
          })
            .then(_ => {
              this.$notify.success({
                title: this.$t('cloud.title.success'),
                message: this.$t('cloud.title.request_done')
              });
              this.$store.dispatch('refreshCapability');
            })
            .catch(error => {
              console.error(error);
              this.$alert(
                this.$t('cloud.title.request_fail'),
                this.$t('cloud.title.error'),
                {
                  confirmButtonText: this.$t('button.check')
                }
              )
            })
          return;
        }

        axios.post(`/api/install/subscriptions/`, {
          period_day: this.days,
          service_key: this.service_key
        })
          .then(_ => {
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done')
            });
            this.$store.dispatch('refreshCapability');
          })
          .catch(error => {
            console.error(error);
            this.$alert(
              this.$t('cloud.title.request_fail'),
              this.$t('cloud.title.error'),
              {
                confirmButtonText: this.$t('button.check')
              }
            )
          })
      })
    },
    onDelete(id) {
      this.$confirm(this.$t('cloud.title.confirm_remove2'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        axios.delete(`/api/install/subscriptions/${id}/`)
          .then(_ => {
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done')
            });
            this.$store.dispatch('refreshCapability');
          })
          .catch(error => {
            console.error(error);
            this.$alert(
              this.$t('cloud.title.request_fail'),
              this.$t('cloud.title.error'),
              {
                confirmButtonText: this.$t('button.check')
              }
            )
          })
          .finally(_ => {
            this.loading = false;
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.normal-input {
  width: 80%;
}
.short-input {
  width: 40%;
}
</style>