<template>
  <div>
    <ConfirmModal ref="AcquisitionConfirmModal" @ok="onConfirmedAcquisition" @cancel="onCanceledAcquisition" content="Obtain administrative authority for the organization. Continue?" />
    <ConfirmModal ref="ReturnConfirmModal" @ok="onConfirmedReturn" content="Return the authority for the organization. Continue?" />
    <OrganizationForm title="New Organization" ref="FormModal" :organization="init_form" @payload="onPayload" />
    <OrganizationView v-if="is_view"
      :organization="init_form"
      @back="onBack"
      @edit="onEdit">
    </OrganizationView>
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <Search @search="onSearch" v-if="!is_view && capability">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-storage" />
                <strong>{{$t('cloud.title.organization')}}</strong>
                <span v-if="count > 0"> ({{count}}) </span>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="list"
                :fields="FIELDS"
                :items-per-page="10"
              >
                <td slot="img" slot-scope="{item}" class="m-0 p-0">
                  <CImg class="logo_size" :src="logoPath(item.logo)" />
                </td>
                <td slot="permission" slot-scope="{item}" class="m-1">
                  <CButton v-if="item.id !== capability.organization.id" color="light" @click="onAcquireAuthority(item.id)">Obtain</CButton>
                  <CButton v-else color="success" @click="onReturnAuthority(item.id)">Return</CButton>
                </td>
              </CDataTable>
            </CCardBody>
          </CCard>
        </Search>
        <CRow class="justify-content-end" v-if="pageTotal > 1 && !loading">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
          <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import OrganizationView from './OrganizationView.vue'
import OrganizationForm from './OrganizationForm.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Organizations',
  components: {
    Search,
    ConfirmModal,
    OrganizationView,
    OrganizationForm
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ])
  },
  data () {
    return {
      FIELDS: [
        {key: 'partner_name', label: 'Partner'},
        {key: 'name', label: 'Name'},
        {key: 'img', label: 'Logo'},
        {key: 'num_alarm', label: 'Alarms'},
        {key: 'num_alarm_24', label: 'Alarms (24h)'},
        {key: 'num_user', label: 'Users'},
        {key: 'num_branch', label: 'Branches'},
        {key: 'num_device', label: 'Devices'},
        {key: 'permission', label: 'Permission'}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],
      loading: false,

      confirm_show: false,

      is_view : false,
      init_form : null,
      regions : [],
      selected_regions : [],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent,
        ipp: 10
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading = true;
      axios.get(`/api/organizations/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
          this.list = [];
        })
        .finally(() => {
          this.loading = false;
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd() {
      this.init_form = null;
      this.$refs.FormModal.open();
    },
    onView(organization) {
      this.init_form = organization
      if (this.capability.organization.id !== organization.id) {
        this.$refs.AcquisitionConfirmModal.open(organization.id);
      } else {
        this.is_view = true;
      }
    },
    onBack() {
      this.is_view = false;
      this.init_form = null;
      this.getList();
    },
    onEdit() {
      this.$refs.FormModal.open();
    },
    onPayload(payload, id) {
      if (id) {
        axios.put(`/api/organizations/${id}/`, payload)
          .then(result => {
            this.getList();
            this.init_form = result.data;
            this.onConfirmedAcquisition(id);
            this.$notify.success({
              title: '완료',
              message: `항목이 변경되었습니다`,
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
              offset: 30
            });
          })
        return;
      }
      axios.post(`/api/organizations/`, payload)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 추가되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onAcquireAuthority(id) {
      this.$refs.AcquisitionConfirmModal.open(id);
    },
    onReturnAuthority() {
      this.$refs.ReturnConfirmModal.open();
    },

    onConfirmedAcquisition(payload) {
      axios.put(`/api/organizations/owner/`, {organization_id: payload})
        .then(() => {
          // this.$store.dispatch('refreshCapability');
          // if (this.init_form) {
          //   this.is_view = true;
          // }
          window.location.reload();
        })
        .catch(() => {
          this.init_form = null;
        })
    },
    onCanceledAcquisition() {
      this.init_form = null;
    },
    onConfirmedReturn() {
      axios.delete(`/api/organizations/owner/`)
        .then(() => {
          // this.$store.dispatch('refreshCapability');
          window.location.reload();
        })
    },
    logoPath(path){
      if(path===null)
        return '/img/no-logo5.jpg';
      else
        return path;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.logo_size {
  height: 34px;
  margin: 6px;
}
.clickable-card-wrapper-add {
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.clickable-card-wrapper-add:hover {
  border: 2px solid $warning;
  margin: 0px 0px 20px 0px;
}
.clickable-card-wrapper {
  border: 0px;
  margin: 2px 2px 22px 2px;
  cursor: pointer;
}
.clickable-card-wrapper:hover {
  border: 2px solid $info;
  margin: 0px 0px 20px 0px;
}
</style>